import React, { useRef } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useIsWrongNetwork } from 'src/hooks/useIsWrongNetwork';
import { useModalContext } from 'src/hooks/useModal';
import { useWeb3Context } from 'src/libs/hooks/useWeb3Context';
import { getNetworkConfig } from 'src/utils/marketsAndNetworksConfig';

import { selectEnrichedLeveragedUniswapPosition, selectEnrichedUniswapPositions } from '../../../store/positionSelectors';
import { EControlPositionType, IEnrichedUniswapPosition } from '../../../types/uniswapTokens';
import { useLeveragedPositionsDataQuery } from '../../../hooks/yldr/useLeveragedPositionsDataQuery';
import { useSuppliedPositionsDataQuery } from '../../../hooks/yldr/useSuppliedPositionsDataQuery';
import { usePositionsDataQuery } from '../../../hooks/yldr/usePositionsDataQuery';
import { useConfiguredAutomationsQuery } from '../../../hooks/yldr/useConfiguredAutomationsQuery';
import { useRootStore } from '../../../store/root';
import { findTokenInReserves } from '../../../utils/findTokenInReserves';
import { ComputedReserveData, useAppDataContext } from '../../../hooks/app-data-provider/useAppDataProvider';
import { ScrollModalHeader } from '../../primitives/ScrollModal';
import { ChangeNetworkWarning } from '../Warnings/ChangeNetworkWarning';
import { TConfiguredAutomationsData } from '../AutoRebalance/types';
import { TxModalTitle, TxModalTitleModern } from './TxModalTitle';
import { TxErrorView } from './Error';
import { useLeveragedErc20PositionsDataQuery } from '../../../hooks/yldr/useLeveragedErc20PositionsDataQuery';

export interface PositionModalWrapperProps {
  tokenId?: string;
  suppliedTokenId?: string;
  positionAddress?: string;
  isWrongNetwork: boolean;
  enrichedPosition: IEnrichedUniswapPosition;
  tokenA: ComputedReserveData;
  tokenB: ComputedReserveData;
  configuredAutomationsData: TConfiguredAutomationsData | undefined;
}

export const PositionModalWrapper: React.FC<{
  title?: string;
  positionAddress?: string;
  tokenId?: string;
  suppliedTokenId?: string;
  requiredChainId?: number;
  modernStyle?: boolean;
  controlType?: EControlPositionType;
  children: (props: PositionModalWrapperProps) => React.ReactNode;
}> = ({
  children,
  tokenId,
  suppliedTokenId,
  positionAddress,
  requiredChainId: _requiredChainId,
  title,
  modernStyle,
  controlType,
}) => {
  const enrichedPositionMemoRef = useRef<IEnrichedUniswapPosition>();
  const { readOnlyModeAddress } = useWeb3Context();
  const { txError, mainTxState } = useModalContext();
  const { isWrongNetwork, requiredChainId } = useIsWrongNetwork(_requiredChainId);
  const { reserves, loading: isLoadingAppDataContext  } = useAppDataContext();

  const { data: positionsData } = usePositionsDataQuery(tokenId ? [tokenId] : undefined);
  const { data: suppliedPositionsData } = useSuppliedPositionsDataQuery(suppliedTokenId ? [suppliedTokenId] : undefined);
  const { data: leveragedPositionsData } = useLeveragedPositionsDataQuery(positionAddress ? [positionAddress] : undefined);
  const { data: leveragedErc20PositionsData } = useLeveragedErc20PositionsDataQuery(positionAddress ? [positionAddress] : undefined);

  const enrichedPositionsData = useRootStore((state) => {
    if (controlType === EControlPositionType.AlmPosition) {
      return selectEnrichedLeveragedUniswapPosition(state, leveragedErc20PositionsData);
    }
    if (tokenId) {
      return selectEnrichedUniswapPositions(state, positionsData);
    }
    if (suppliedTokenId) {
      return selectEnrichedUniswapPositions(state, suppliedPositionsData);
    }
    if (positionsData) {
      return selectEnrichedLeveragedUniswapPosition(state, leveragedPositionsData);
    }
  });

  const enrichedPosition = enrichedPositionsData?.length ? enrichedPositionsData[0] : undefined;
  const tokenA = findTokenInReserves(reserves, enrichedPosition?.token0.underlyingAsset);
  const tokenB = findTokenInReserves(reserves, enrichedPosition?.token1.underlyingAsset);

  if (enrichedPosition) {
    enrichedPositionMemoRef.current = enrichedPosition;
  }

  const enabledConfiguredAutomations = Boolean(positionAddress && controlType !== EControlPositionType.AlmPosition);

  const { data: configuredAutomationsData, isLoading: isLoadingAutomationsData } = useConfiguredAutomationsQuery({
    marketId: enrichedPosition?.marketId,
    positions: positionAddress ? [positionAddress] : undefined,
    enabled: enabledConfiguredAutomations,
  });

  const isLoading =
    enabledConfiguredAutomations && isLoadingAutomationsData ||
    isLoadingAppDataContext;

  if (txError && txError.blocking) {
    return <TxErrorView txError={txError} />;
  }

  return (
    <>
      {Boolean(title) && (
        <ScrollModalHeader withDivider={modernStyle}>
          {!mainTxState.success && !modernStyle && (
            <TxModalTitle
              title={title}
              symbol={
                enrichedPosition
                  ? `${enrichedPosition.token0.symbol} / ${enrichedPosition.token1.symbol}`
                  : undefined
              }
              sx={{ mb: 0 }}
            />
          )}
          {!mainTxState.success && modernStyle && (
            <TxModalTitleModern
              title={title}
              marketName={enrichedPosition?.marketName}
              tokenA={enrichedPosition?.token0}
              tokenB={enrichedPosition?.token1}
              fee={enrichedPosition?.fee}
            />
          )}
          {isWrongNetwork && !readOnlyModeAddress && (
            <ChangeNetworkWarning
              networkName={getNetworkConfig(requiredChainId).name}
              chainId={requiredChainId}
              sx={{
                mt: 4,
                mb: -1,
              }}
            />
          )}
        </ScrollModalHeader>
      )}
      {enrichedPositionMemoRef.current && !isLoading && tokenA && tokenB ? children({
        enrichedPosition: enrichedPositionMemoRef.current,
        configuredAutomationsData,
        isWrongNetwork,
        positionAddress,
        suppliedTokenId,
        tokenId,
        tokenA,
        tokenB,
      }) : (
        <Box sx={{
          minHeight: '400px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
